import Axios from 'axios';

import { getCheckout } from '../../shared/helpers/getCheckout';
import { CreateUser } from '../../shared/types/createUser';
import { HttpResponse } from '../../shared/types/http-response';
import { User } from '../../shared/types/user';
import { UserCredentials } from '../../shared/types/userCredentials';
import { getErrorMessage } from '../helpers/get-error-message-from-http-response';
import { getAffiliateFromURLSearchParams } from '../helpers/getAffiliateFromURLSearchParams';
import { getTarget } from '../helpers/getTarget';

const registerUser = async (
    credentials: UserCredentials,
    recaptchaToken: string,
    search: URLSearchParams,
): Promise<{
    userId: string;
    target: string
}> => {
    const affiliate = getAffiliateFromURLSearchParams(search);

    const createUserRequestDto: CreateUser = {
        phoneNumber: credentials.phoneNumber,
        fullName: credentials.fullName,
        email: credentials.email,
        password: credentials.password,
        network: credentials.network,
        affiliate,
        recaptchaToken,
        ...(!!credentials.encryptedMsisdn && { encryptedMsisdn: credentials.encryptedMsisdn }),
    };

    try {
        const res = await Axios.post<HttpResponse<User>>('/api/create-user', createUserRequestDto, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        const target = getTarget(getCheckout(search));
        const userId = res.data.body?.id as string

        return {
            userId,
            target
        }

    } catch (e) {
        if (Axios.isAxiosError(e)) {
            throw new Error(getErrorMessage(e.response, 'Failed to create account'));
        }

        throw new Error('Something went wrong');
    }
};

export { registerUser };
